
// Enable Fivh-on-mobile-devices fix
const agent = window.navigator.userAgent
const element = document.documentElement
let timeout

function onOrientationChange () {
  element.classList.remove('is-buggy-vh-device')

  window.clearTimeout(timeout)
  timeout = window.setTimeout(() => element.classList.add('is-buggy-vh-device'), 500)
}

if (agent.match(/Android/) || agent.match(/iPhone/) || agent.match(/iPad/)) {
  element.classList.add('is-buggy-vh-device')

  window.addEventListener('orientationchange', onOrientationChange)
}
