
// Take pre-existing classes
const classes = Array.from(document.documentElement.classList)

// Enable JS className
classes.push('has-js')

// Enable hover(), focus() and hover-and-focus() mixins
classes.push(('ontouchstart' in window) ? 'touch' : 'no-touch')

// Enable Edge classNames (is-edge)
const edge = window.navigator.userAgent.indexOf('Edge')

if (edge !== -1) {
  classes.push('is-edge')

  const version = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10)

  if (version) {
    classes.push('is-edge' + version)
  }
}

// Enable IE classNames (is-ie, is-ie9, is-ie10, is-ie11)
if (document.documentMode) {
  classes.push('is-ie')
  classes.push('is-ie' + document.documentMode)
}

// Enable iOS classnames (is-ipad, is-iphone, is-ios)
if (navigator.userAgent.indexOf('iPad') !== -1) {
  classes.push('is-ipad')
  classes.push('is-ios')
} else if (navigator.userAgent.indexOf('iPhone') !== -1) {
  classes.push('is-iphone')
  classes.push('is-ios')
}

// Enable OS classnames (is-osx, is-windows)
if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
  classes.push('is-osx')
} else if (navigator.userAgent.indexOf('Windows') !== -1) {
  classes.push('is-windows')
}

// Enable Android classNames (is-android)
if (navigator.userAgent.indexOf('Android') !== -1) {
  classes.push('is-android')
}

// Apply classes in one go to prevent performance issues (yes, really)
document.documentElement.className = classes.join(' ')
